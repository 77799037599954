<template>
  <v-card elevation="0" class="elevation-0" v-model="dialog">
    <template v-if="entryDetails">
      <v-card elevation="0" v-if="entryName == 'md_history' && entryDetails">
        <v-toolbar flat dark color="white" class="text-primary py-2">
          <v-toolbar-title>
            {{ gettranslatedname(entryDetails.history_type) }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="mx-2"
            small
            fab
            elevation="0"
            color="rgba(165, 203, 239, 0.20)"
            @click="updateRecord()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="edit-alt"
              fill="#0971CE"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn
            small
            fab
            elevation="0"
            color="rgba(239, 165, 179, 0.25)"
            @click="deleteItem()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="trash-alt"
              fill="#f64e60"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn icon dark color="primary" @click="closeentrydetail()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-title>
          {{ entryDetails.incident_date | moment("LL") }}
        </v-card-title>

        <v-card-text>
          {{ entryDetails.history_body }}
        </v-card-text>
      </v-card>

      <v-card
        elevation="0"
        v-else-if="entryName == 'md_vitals' && entryDetails"
      >
        <v-toolbar flat dark color="white" class="text-primary py-2">
          <v-toolbar-title>
            {{ gettranslatedname(entryDetails.vital_type) }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="mx-2"
            small
            fab
            elevation="0"
            color="rgba(165, 203, 239, 0.20)"
            @click="updateRecord()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="edit-alt"
              fill="#0971CE"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn
            small
            fab
            elevation="0"
            color="rgba(239, 165, 179, 0.25)"
            @click="deleteItem()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="trash-alt"
              fill="#f64e60"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn icon dark color="primary" @click="closeentrydetail()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <span
            :style="'color:' + entryDetails.health"
            class="font-weight-bold font-size-h5 mr-2"
            >{{ entryDetails.reading }}
            {{ entryDetails.vital_type.suffix }}</span
          >
        </v-card-title>

        <v-card-subtitle>
          {{ entryDetails.created_on | moment("LL") }}
        </v-card-subtitle>
      </v-card>

      <v-card
        elevation="0"
        v-else-if="entryName == 'md_diagnoses' && entryDetails"
      >
        <v-toolbar flat dark color="white" class="text-primary py-2">
          <v-toolbar-title>
            {{ entryDetails.icd.Description }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="mx-2"
            v-if="$user.getCurrentUser().role.id != 4"
            small
            fab
            elevation="0"
            color="rgba(165, 203, 239, 0.20)"
            @click="updateRecord()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="edit-alt"
              fill="#0971CE"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn
            small
            fab
            elevation="0"
            color="rgba(239, 165, 179, 0.25)"
            @click="deleteItem()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="trash-alt"
              fill="#f64e60"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn icon dark color="primary" @click="closeentrydetail()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div style="display:flex">
          <v-card-title style="    min-width: 51px;">
            By
          </v-card-title>
          <v-list-item two-line>
            <v-list-item-avatar size="47" color="primary">
              <v-img
                v-if="entryDetails.owner.avatar != null"
                :src="entryDetails.owner.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ entryDetails.owner.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ entryDetails.owner.first_name }}
                {{ entryDetails.owner.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ entryDetails.created_on | moment("LL") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-card-title>
          Details
        </v-card-title>
        <v-card-text>
          <span class="mr-2">{{ entryDetails.examination }}</span>
        </v-card-text>
      </v-card>

      <v-card elevation="0" v-else-if="entryName == 'md_notes' && entryDetails">
        <v-toolbar flat dark color="white" class="text-primary py-2">
          <v-toolbar-title>
            {{ $t("MF.notesLabel") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="mx-2"
            small
            fab
            elevation="0"
            color="rgba(165, 203, 239, 0.20)"
            @click="updateRecord()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="edit-alt"
              fill="#0971CE"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn
            small
            fab
            elevation="0"
            color="rgba(239, 165, 179, 0.25)"
            @click="deleteItem()"
          >
            <unicon
              style="margin-right: auto;margin-left: auto;"
              name="trash-alt"
              fill="#f64e60"
              height="20"
            ></unicon>
          </v-btn>
          <v-btn icon dark color="primary" @click="closeentrydetail()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div style="display:flex">
          <v-card-title style="    min-width: 51px;">
            By
          </v-card-title>
          <v-list-item two-line>
            <v-list-item-avatar size="47" color="primary">
              <v-img
                v-if="entryDetails.owner.avatar != null"
                :src="entryDetails.owner.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ entryDetails.owner.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ entryDetails.owner.first_name }}
                {{ entryDetails.owner.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ entryDetails.created_on | moment("LL") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-card-title>
          Details
        </v-card-title>
        <v-card-text>
          {{ entryDetails.note_body }}
        </v-card-text>
      </v-card>
    </template>
    <v-card elevation="0" v-else>
      <h5>👈 {{ $t("MF.entryTip") }}</h5>
    </v-card>

    <!-- delete item -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this File?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- update entries -->
    <!-- 
            @closeDialog="openEditDialog = false"
      @formSubmitted="formSubmitted"
      :dialog="openEditDialog"
      :record="record"
      v-if="openEditDialog"
     -->
    <HistoryForm
      v-if="selectedEntryType == 'md_history'"
      :record="record"
      :dialog="selectedEntryType ? true : false"
      @closeClicked="selectedEntryType = null"
      @backToStepOneAndToast="backAndToast($event)"
    />
    <VitalsForm
      v-if="selectedEntryType == 'md_vitals'"
      :record="record"
      :dialog="selectedEntryType ? true : false"
      @closeClicked="selectedEntryType = null"
      @backToStepOneAndToast="backAndToast($event)"
    />
    <DiagnosesForm
      v-if="selectedEntryType == 'md_diagnoses'"
      :record="record"
      :dialog="selectedEntryType ? true : false"
      @closeClicked="selectedEntryType = null"
      @backToStepOneAndToast="backAndToast($event)"
    />

    <FilessForm
      v-if="selectedEntryType == 'files'"
      :record="record"
      :dialog="selectedEntryType ? true : false"
      @closeClicked="selectedEntryType = null"
      @backToStepOneAndToast="backAndToast($event)"
    />
    <NotesForm
      v-if="selectedEntryType == 'md_notes'"
      :record="record"
      :dialog="selectedEntryType ? true : false"
      @closeClicked="selectedEntryType = null"
      @backToStepOneAndToast="backAndToast($event)"
    />

    <!-- <ConsultationForm
              v-if="selectedEntryType == 7"
              @backToStepOneAndToast="backAndToast($event)"
            /> -->
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import HistoryForm from "./addcomp/historyForm.vue";
import VitalsForm from "./addcomp/vitalsForm.vue";
import NotesForm from "./addcomp/notesForm.vue";
import DiagnosesForm from "./addcomp/diagnosesForm.vue";
import FilessForm from "./addcomp/filesForm.vue";
// import ConsultationForm from './addcomp/ConsultationForm.vue';

export default {
  name: "entry_details",
  props: ["entryName", "entryDetails", "dialog"],
  components: {
    HistoryForm,
    VitalsForm,
    NotesForm,
    DiagnosesForm,
    FilessForm
    // ConsultationForm,
  },
  data() {
    return {
      selectedFile: null,
      isViewOpen: false,
      record: null,
      openEditDialog: false,
      selectedEntryType: null,
      loadingStatus: false,
      dialogDelete: false
    };
  },

  methods: {
    gettranslatedname(item) {
      if (item.translation.length > 0) {
        for (var i = 0; i < item.translation.length; i++) {
          if (item.translation[i].lang == this.$root.lang) {
            return item.translation[i].name;
          }
        }
      } else {
        return item.name;
      }
    },
    setFileIcon(type) {
      var path = "/media/svg/files/";
      if (type.includes("pdf")) {
        return path + "pdf.svg";
      } else if (type.includes("jpeg") || type.includes("jpg")) {
        return path + "jpg.svg";
      } else if (type.includes("mp4")) {
        return path + "mp4.svg";
      } else {
        return path + "doc.svg";
      }
    },
    selectFile(f) {
      this.selectedFile = f;
      this.isViewOpen = true;
    },
    closeFileView(d) {
      this.isViewOpen = d;
    },
    closeentrydetail() {
      this.$emit("closedetail");
    },
    async deleteItemConfirm() {
      try {
        this.loadingStatus = true;

        await ApiService.delete(this.entryName, this.entryDetails.id);

        this.loadingStatus = false;
        this.closeDelete();
        this.record = null;
        this.toast(
          "b-toaster-top-center",
          "success",
          "record deleted successfully"
        );
        this.getItems();
      } catch (error) {
        // console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    updateRecord() {
      this.record = this.entryDetails;
      this.selectedEntryType = this.entryName;
      // console.log("update diagnoses", this.record);
      // this.openEditDialog = true;
    },

    backAndToast(msg, append = false) {
      this.record = null;
      this.selectedEntryType = null;
      this.$emit("recordEditted");
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: "b-toaster-top-center",
        solid: true,
        variant: "success",
        appendToast: append
      });
    }
  },
  mounted() {
    // console.log("from entry details", this.entryDetails);
  }
};
</script>
