<template>
  <!-- start mobile view -->
  <div v-if="this.$root.mobile">
    <h4 class="text-primary font-weight-bolder px-1 py-2">
      {{ $t("MF.entriess") }}
    </h4>
    <div class="row grid">
      <div
        v-for="(entry, i) in entries"
        :key="i"
        @click="mutateChild(entry.label)"
        class="col-6"
        style="padding:5px;"
      >
        <div class="griditem">
          <div class="griditemicon">
            <!--<img width="25" :src="$root.assetsBaseURL + departments[0].avatar.data.asset_url" />-->
            <img
              height="25px"
              width="25px"
              :src="'/media/svg/icons/Doctory/' + entry.icon + '.svg'"
            />
          </div>
          <div class="mt-3">
            <div class="griditemtitle">{{ entry.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <v-scroll-x-reverse-transition>
      <div v-if="sheet" class="customfullview">
        <div :style="settoptofixed">
          <v-system-bar
            dark
            color="primary"
            :height="$root.sysbarheight"
            v-if="$root.showBar"
          >
          </v-system-bar>
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="sheet = !sheet">
              <v-icon>{{
                $root.lang == "en" ? "mdi-chevron-left" : "mdi-chevron-right"
              }}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ displayEntry() }}</v-toolbar-title>
          </v-toolbar>
        </div>
        <div
          :style="'width:100%;padding-top:' + (65 + $root.sysbarheight) + 'px'"
        >
          <div v-if="entryName == 'activities'" class="px-5 pt-5">
            <Changes :mfid="this.mfid"></Changes>
          </div>
          <EntrySummary
            ref="entryRef"
            v-else
            :mfid="mfid"
            :entryName="entryName"
            @listEntryClicked="showEntryBottom($event)"
            @showDetailsCom="showDetails22 = true"
          />

          <v-bottom-sheet v-model="entrySheet">
            <v-card
              elevation="0"
              class="rounded-lg"
              :disabled="downloadprogress"
              style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
              :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
            >
              <template
                v-if="
                  entryName == 'md_presc' &&
                    selectedEntry != null &&
                    selectedEntry.medication
                "
              >
                <v-progress-linear
                  indeterminate
                  v-if="downloadprogress"
                ></v-progress-linear>
                <v-toolbar flat dark color="white" class="text-primary py-2">
                  <v-toolbar-title>
                    <span v-if="selectedEntry.medication">{{
                      selectedEntry.medication.length
                    }}</span>
                    {{ $t("MF.prescription.medication") }} {{ doDownload() }}
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    class="mx-2"
                    small
                    fab
                    elevation="0"
                    color="rgba(165, 203, 239, 0.20)"
                    @click="generatePrescription()"
                  >
                    <unicon
                      style="margin-right: auto;margin-left: auto;"
                      name="cloud-download"
                      fill="#0971CE"
                      height="20"
                    ></unicon>
                  </v-btn>

                  <v-btn
                    icon
                    dark
                    color="primary"
                    @click="entrySheet = !entrySheet"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="doDownload()"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  filename="Prescription"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
                  @progress="onProgress($event)"
                  @startPagination="hasStartedGeneration()"
                  @hasDownloaded="hasDownloaded($event)"
                  @beforeDownload="beforeDownload($event)"
                  ref="html2Pdf"
                >
                  <section slot="pdf-content">
                    <v-card-text class="p-10">
                      <div
                        style="display:flex; flex-direction:column; "
                        class="text-primary mb-10"
                      >
                        <img
                          width="200px"
                          src="/media/logos/logo_text.png"
                          alt="Logo"
                        />
                        <div class="font-size-h1 pt-7 font-weight-boldest">
                          {{ $t("MF.prescription.title") }}
                        </div>
                        <div class="font-size-h6 pt-4">
                          {{ $t("APPOINTMENTS.add.dr") }}
                          {{ selectedEntry.owner.first_name }}
                          {{ selectedEntry.owner.last_name }}
                        </div>
                        <div class="font-size-h6 pt-2">
                          {{
                            selectedEntry.created_on
                              | moment("dddd, MMMM Do YYYY")
                          }}
                        </div>
                        <v-divider />
                      </div>

                      <div
                        v-for="(med, idx) in selectedEntry.medication"
                        :key="idx"
                        class=" mb-10"
                      >
                        <div style="display:flex; flex-direction:column;">
                          <div class="font-size-h4 pb-3">
                            {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                            <span class="font-weight-bold">{{
                              med.medicine.name
                            }}</span>
                          </div>
                          <div class="pb-3">
                            <span class="font-size-h6 font-weight-bolder">
                              {{ med.frequancy }}:
                            </span>
                            <span
                              v-for="(time, index) in med.times"
                              :key="time"
                              class="font-size-h6"
                              >{{ time }}
                              <span v-if="index < med.times.length - 1"
                                >,
                              </span></span
                            >
                          </div>
                          <div class="font-size-h6">
                            Expires
                            {{ med.end_date | moment("dddd, MMMM Do YYYY") }}
                          </div>
                        </div>
                      </div>
                      <div class="font-size-h6 pb-4 text-primary">
                        {{ $t("MF.notesLabel") }}
                      </div>
                      <div class="">{{ selectedEntry.notes }}</div>
                    </v-card-text>
                  </section>
                </vue-html2pdf>
                <div style="display:flex">
                  <v-card-title style="    min-width: 51px;">
                    By
                  </v-card-title>
                  <v-list-item two-line>
                    <v-list-item-avatar size="47" color="primary">
                      <v-img
                        v-if="selectedEntry.owner.avatar != null"
                        :src="selectedEntry.owner.avatar.data.thumbnails[0].url"
                      ></v-img>
                      <div
                        v-else
                        style="text-transform: uppercase;width:100%"
                        class="white--text font-size-h4"
                      >
                        {{ selectedEntry.owner.first_name.charAt(0) }}
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ selectedEntry.owner.first_name }}
                        {{ selectedEntry.owner.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ selectedEntry.created_on | moment("LL") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div class="px-2 pb-2">
                  <div
                    v-for="(med, idx) in selectedEntry.medication"
                    :key="idx"
                    class="listitem mb-2"
                  >
                    <v-list-item three-line @click="listItemClicked(item)">
                      <v-list-item-avatar size="47" color="#F2F7FD">
                        <v-img
                          v-if="med.type == 'injection'"
                          src="/media/svg/icons/Doctory/icons-syringe.svg"
                          height="20"
                          width="20"
                          contain
                        />
                        <v-img
                          v-else
                          src="/media/svg/icons/Doctory/icons-pills.svg"
                          height="20"
                          width="20"
                          contain
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          ><div class="listitemtitle">
                            {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                            <span class="font-weight-bold">{{
                              med.medicine.name
                            }}</span>
                          </div></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <span
                            v-for="(time, index) in med.times"
                            :key="time"
                            class="font-size-h6"
                            >{{ time }}
                            <span v-if="index < med.times.length"
                              >,
                            </span></span
                          >

                          <span class="font-size-h6"> {{ med.frequancy }}</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <div class="listitemsubtitle">
                            Expires
                            {{ med.end_date | moment("dddd, MMMM Do YYYY") }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </div>
              </template>

              <div v-else>
                <EntryDetails
                  :dialog="selectedEntry ? true : false"
                  :entryDetails="selectedEntry"
                  :entryName="entryName"
                  @recordEditted="refreshRecord"
                  @closedetail="entrySheet = !entrySheet"
                />
              </div>
            </v-card>
          </v-bottom-sheet>
        </div>
      </div>
    </v-scroll-x-reverse-transition>
    <FileView
      style="z-index:10001"
      :file="selectedFile"
      :dialog="isViewOpen"
      @fileViewClosed="isViewOpen = !isViewOpen"
    ></FileView>
  </div>
</template>

<script>
// import History from './history.vue';
import EntryDetails from "./EntryDetails";
import EntrySummary from "./EntrySummary.vue";
import FileView from "./fileView.vue";
import Changes from "./changes.vue";
import VueHtml2pdf from "vue-html2pdf";
import { Plugins, FilesystemDirectory } from "@capacitor/core";
const { Filesystem, Share } = Plugins;

export default {
  components: { EntryDetails, EntrySummary, FileView, Changes, VueHtml2pdf },
  props: ["mfid"],
  data() {
    return {
      sheet: false,
      downloadprogress: false,
      entryName: "md_history",
      selectedEntry: null,
      settoptofixed:
        "position: fixed;top:0;width: 100%;z-index: 1000;height" +
        (this.$root.sysbarheight + 56) +
        "px",
      entrySheet: false,
      showDetails22: true,
      isViewOpen: false,
      selectedFile: null,
      entries: [
        {
          label: "md_history",
          text: this.$t("MF.medicalHistoryLabel"),
          icon: "icons-pc"
        },
        {
          label: "md_vitals",
          text: this.$t("MF.vitalslabel"),
          icon: "icons-monitoring"
        },
        {
          label: "md_diagnoses",
          text: this.$t("MF.diagnosesLabel"),
          icon: "icons-doctor"
        },
        {
          label: "md_notes",
          text: this.$t("MF.notesLabel"),
          icon: "group-700"
        },
        {
          label: "md_presc",
          text: this.$t("MF.prescriptionLabel"),
          icon: "icons-pills"
        },
        {
          label: "md_files",
          text: this.$t("MF.filesLabel"),
          icon: "icons-file-records"
        },
        {
          label: "activities",
          text: this.$t("MF.changes.summery"),
          icon: "icons-nurse"
        }
      ]
    };
  },
  methods: {
    openpdftodownload(item) {
      // console.log("opening pdf");
      this.toexport = item;
      this.openpdfview = true;
    },
    generatePrescription() {
      this.downloadprogress = true;
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 500);
    },
    onProgress() {
      // console.log("progress ", e);
      // console.log("ref pdf is", this.$refs.html2Pdf);
    },
    hasStartedGeneration() {
      // console.log("started genration");
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then(pdf => {
          //console.log("pdf generated",pdf.output('bloburl'));
          this.downloadprogress = false;
          this.fileWrite(pdf.output("blob"));
          //console.log("blob", pdf.output('blob'));
        });
    },
    doDownload() {
      // console.log("device is",localStorage.device)
      if (localStorage.device == "ios" || localStorage.device == "android") {
        // console.log("this is android or ios, do not download", false)
        return false;
      }
      // console.log("this is web, do download", true)
      return true;
    },
    async hasDownloaded(e) {
      // console.log("has generated", e);
      this.downloadprogress = false;
      this.fileWrite(e);
    },
    async fileWrite(file) {
      try {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async function() {
          var base64data = reader.result;
          //console.log(base64data);

          const result = await Filesystem.writeFile({
            path: "prescription.pdf",
            data: base64data,
            directory: FilesystemDirectory.Documents
          });

          try {
            await Share.share({
              title: "Prescprtion",
              text: "Share your prescprtion",
              url: result.uri,
              dialogTitle: "Send to"
            });
          } catch (e) {
            console.error("Unable to share file", e);
          }
        };
      } catch (e) {
        console.error("Unable to write file", e);
      }
    },
    mutateChild(label) {
      this.entryName = label;
      this.sheet = !this.sheet;
    },
    checkMargin() {
      if (this.$root.mobile) {
        return "margin-top:50px !important";
      }
      return "";
    },
    displayEntry() {
      switch (this.entryName) {
        case "md_history":
          return this.$t("MF.medicalHistoryLabel");

        case "md_vitals":
          return this.$t("MF.vitalslabel");

        case "md_diagnoses":
          return this.$t("MF.diagnosesLabel");

        case "md_notes":
          return this.$t("MF.notesLabel");

        case "md_presc":
          return this.$t("MF.prescriptionLabel");

        case "md_files":
          return this.$t("MF.filesLabel");
        case "activities":
          return this.$t("MF.changes.summery");

        default:
          return "wrong entry selected";
      }
    },
    showEntryBottom(e) {
      if (e.directus_files_id) {
        this.selectedFile = e.directus_files_id;
        this.isViewOpen = true;
      } else if (e.medication) {
        this.selectedEntry = e;
        this.entrySheet = true;
      } else {
        this.showDetails22 = false;
        this.selectedEntry = e;
        this.entrySheet = true;
      }
    },
    refreshRecord() {
      // console.log("refreshRecord called");
      this.selectedEntry = null;
      this.entrySheet = false;
      this.$refs.entryRef.getEntryData();
    }

    // showDetails() {
    //   console.log('hi 36');
    //   this.$refs.entryDetailsRef.toggleShowDetails();
    // },
  }
};
</script>

<style></style>
