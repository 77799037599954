<template>
  <div>
    <div class="text-center" v-if="loadingStatus">
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>

    <template v-else>
      <div class="customnav px-3">
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('APPOINTMENTS.APP.search')"
          filled
          color="primary"
          background-color="rgba(165, 203, 239, 0.42)"
          rounded
          hide-details
          dense
        />
        <div
          v-if="showfilter"
          class="filterbtn"
          @click="filtersheet = !filtersheet"
        >
          <v-icon color="primary">mdi-filter-variant</v-icon>
        </div>
      </div>

      <v-list
        v-if="entryName == 'md_history'"
        three-line
        class="bg-transparent px-3"
      >
        <div
          v-for="item in filteredMDHistory"
          :key="item.id"
          class="listitem mb-2"
        >
          <v-list-item three-line @click="listItemClicked(item)">
            <v-list-item-avatar class="listitemicon" size="47" color="#F2F7FD">
              <v-img
                v-if="item.history_type.icon"
                :src="
                  $root.assetsBaseURL +
                    '' +
                    item.history_type.icon.data.asset_url
                "
                height="20"
                width="20"
                contain
              />
              <div
                v-else
                class="primary--text"
                style="background-color:#F2F7FD"
              >
                D
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><div class="listitemtitle">
                  <span>{{ gettranslatedname(item.history_type) }}</span>
                </div></v-list-item-title
              >
              <v-list-item-subtitle>
                <span>{{ item.history_body }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle>
                <div class="listitemsubtitle">
                  {{ item.incident_date | moment("from", "now") }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div class="text-center mt-10" v-if="filteredMDHistory.length == 0">
          <span class="text-muted font-size-h4">{{
            $t("MF.history.empty")
          }}</span>
        </div>
      </v-list>

      <v-list
        v-else-if="entryName == 'md_vitals'"
        two-line
        class="bg-transparent px-3"
      >
        <div
          v-for="item in filteredVitals"
          :key="item.id"
          class="listitem mb-2"
        >
          <v-list-item @click="listItemClicked(item)">
            <v-list-item-avatar class="listitemicon" size="47" color="#F2F7FD">
              <v-img
                :src="
                  $root.assetsBaseURL + '' + item.vital_type.icon.data.asset_url
                "
                height="20"
                width="20"
                contain
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="listitemtitle">
                <span>{{ gettranslatedname(item.vital_type) }}</span
                ><br />
                <span :style="'color:' + item.health"
                  >{{ item.reading }} {{ item.vital_type.suffix }}</span
                >
              </div>
              <div class="listitemsubtitle mt-2">
                {{ item.created_on | moment("from", "now") }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <v-list
        v-else-if="entryName == 'md_diagnoses'"
        three-line
        class="bg-transparent px-3"
      >
        <div
          v-for="item in filteredDiagnoses"
          :key="item.id"
          class="listitem mb-2"
        >
          <v-list-item three-line @click="listItemClicked(item)">
            <v-list-item-avatar size="47" color="primary">
              <div
                v-if="item.owner == null"
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                D
              </div>
              <v-img
                v-else-if="item.owner.avatar != null"
                :src="item.owner.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.owner.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><div class="listitemtitle">
                  <span>{{ item.icd.Description }}</span>
                </div></v-list-item-title
              >
              <v-list-item-subtitle>
                <span>{{ item.examination }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle>
                <div class="listitemsubtitle">
                  {{ item.created_on | moment("from", "now") }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <v-list
        v-else-if="entryName == 'md_notes'"
        two-line
        class="bg-transparent px-3"
      >
        <div v-for="item in filteredNotes" :key="item.id" class="listitem mb-2">
          <v-list-item three-line @click="listItemClicked(item)">
            <v-list-item-avatar size="47" color="primary">
              <div
                v-if="item.owner == null"
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                D
              </div>
              <v-img
                v-else-if="item.owner.avatar != null"
                :src="item.owner.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.owner.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><div class="listitemtitle">
                  <span
                    >{{ item.owner.first_name }}
                    {{ item.owner.last_name }}</span
                  >
                </div></v-list-item-title
              >
              <v-list-item-subtitle>
                <span>{{ item.note_body }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle>
                <div class="listitemsubtitle">
                  {{ item.created_on | moment("from", "now") }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <v-list
        v-else-if="entryName == 'md_presc'"
        three-line
        class="bg-transparent px-3"
      >
        <div v-for="item in filteredPresc" :key="item.id" class="listitem mb-2">
          <v-list-item three-line @click="listItemClicked(item)">
            <v-list-item-avatar size="47" color="primary">
              <div
                v-if="item.owner == null"
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                D
              </div>
              <v-img
                v-else-if="item.owner.avatar != null"
                :src="item.owner.avatar.data.thumbnails[0].url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.owner.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                ><div class="listitemtitle">
                  <span
                    >{{ item.medication.length }}
                    {{ $t("MF.prescription.medication") }}</span
                  >
                </div></v-list-item-title
              >
              <v-list-item-subtitle>
                <span>{{ item.notes }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle>
                <div class="listitemsubtitle">
                  {{ item.created_on | moment("from", "now") }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>

      <v-list
        v-else-if="entryName == 'md_files'"
        three-line
        class="bg-transparent px-3"
      >
        <div v-for="item in filteredFiles" :key="item.id" class="listitem mb-2">
          <v-list-item three-line @click="listItemClicked(item)">
            <v-list-item-avatar tile size="47">
              <img
                alt=""
                class="max-h-45px mr-4"
                :src="setFileIcon(item.directus_files_id.type)"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="listitemtitle">
                  {{
                    item.directus_files_id.filename_download.length > 34
                      ? item.directus_files_id.filename_download.slice(0, 35) +
                        "..."
                      : item.directus_files_id.filename_download
                  }}
                </div>
              </v-list-item-title>

              <v-list-item-subtitle>
                <div class="listitemsubtitle">
                  {{
                    item.directus_files_id.uploaded_on | moment("from", "now")
                  }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </template>
    <v-bottom-sheet v-model="filtersheet">
      <v-card
        class="rounded-lg"
        style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
        :style="'padding-bottom: ' + this.$root.tabspadding + 'px;'"
      >
        <v-toolbar flat dark color="white" class="text-primary py-0">
          Select a Type
          <v-spacer />
          <v-btn icon dark color="primary" @click="filtersheet = !filtersheet">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div
          v-if="
            (entryName == 'md_vitals' || entryName == 'md_history') &&
              type_list != null
          "
        >
          <v-list>
            <v-list-item
              @click="
                type_selected = '';
                filtersheet = !filtersheet;
              "
            >
              <v-list-item-avatar>
                <unicon
                  style="margin-right: auto;margin-left: auto;"
                  name="list-ul"
                  fill="#0971ce"
                ></unicon>
              </v-list-item-avatar>
              <v-list-item-title>All Entries </v-list-item-title>
            </v-list-item>
            <template v-for="item in type_list">
              <v-list-item
                @click="
                  type_selected = item.id;
                  filtersheet = !filtersheet;
                "
                :key="item.id"
              >
                <v-list-item-avatar>
                  <v-img
                    :src="$root.assetsBaseURL + '' + item.icon.data.asset_url"
                    height="15"
                    width="15"
                    contain
                  />
                </v-list-item-avatar>
                <v-list-item-title
                  >{{ gettranslatedname(item) }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import Avatar from '@/view/pages/utilComponents/avatar2.vue';
export default {
  props: ["mfid", "entryName"],
  data() {
    return {
      loadingStatus: true,
      entryData: [],
      selectedFile: null,
      isViewOpen: false,
      search: "",
      type_list: null,
      type_selected: "",
      filtersheet: false,
      showfilter: false
    };
  },
  watch: {
    entryName: function(newentryname) {
      if (newentryname != "") {
        this.getEntryData();
        this.loadingStatus = true;
      }
    }
  },
  methods: {
    gettranslatedname(item) {
      if (item.translation.length > 0) {
        for (var i = 0; i < item.translation.length; i++) {
          if (item.translation[i].lang == this.$root.lang) {
            return item.translation[i].name;
          }
        }
      } else {
        return item.name;
      }
    },
    async getEntryData() {
      let url = null;
      let custom = false;
      try {
        switch (this.entryName) {
          case "md_history":
            url =
              "md_history?fields=id,history_type.id,history_type.type,incident_date,history_type.icon.data,history_body,history_type.translation.*&sort=-incident_date&filter[medical_file]=" +
              this.mfid;
            custom = false;
            this.showfilter = true;
            break;

          case "md_vitals":
            url =
              "md_vitals?fields=id,vital_type.id,vital_type.name,vital_type.suffix,vital_type.icon.data,created_on,reading,method,health,vital_type.translation.*&sort=-created_on&filter[medical_file]=" +
              this.mfid;
            custom = false;
            this.showfilter = true;
            break;

          case "md_diagnoses":
            url =
              "md_diagnoses?fields=id,examination,icd,owner.first_name,owner.last_name,owner.avatar.data,created_on&sort=-created_on&filter[medical_file]=" +
              this.mfid;
            custom = false;
            break;

          case "md_notes":
            url =
              "md_notes?fields=id,note_body,summary,owner.first_name,owner.last_name,owner.avatar.data,created_on&sort=-created_on&filter[medical_file]=" +
              this.mfid;
            custom = false;
            break;

          case "md_presc":
            url = "prescriptions/" + this.mfid;
            custom = true;

            break;

          case "md_files":
            url =
              "medical_file_directus_files?fields=directus_files_id.title,directus_files_id.type,directus_files_id.uploaded_by,directus_files_id.filename_download,directus_files_id.filesize,directus_files_id.data,directus_files_id.uploaded_on&filter[medical_file_id]=" +
              this.mfid;
            custom = false;

            break;

          default:
            url = null;
            custom = false;
            break;
        }

        if (url && !custom) {
          this.loadingStatus = true;
          if (this.entryName == "md_vitals") {
            await ApiService.get(
              "items",
              "vital_types?fields=id,icon.data,translation.*"
            ).then(({ data }) => {
              data.data = data.data.map(d => {
                d.owner =
                  d.owner === null
                    ? { first_name: "Doctory", last_name: "Doctor" }
                    : d.owner;
                return d;
              });
              this.type_list = data.data;
            });
          } else if (this.entryName == "md_history") {
            await ApiService.get(
              "items",
              "history_type?fields=id,icon.data,translation.*"
            ).then(({ data }) => {
              data.data = data.data.map(d => {
                d.owner =
                  d.owner === null
                    ? { first_name: "Doctory", last_name: "Doctor" }
                    : d.owner;
                return d;
              });
              this.type_list = data.data;
            });
          }
          const { data } = await ApiService.get("items", url);
          data.data = data.data.map(d => {
            d.owner =
              d.owner === null
                ? { first_name: "Doctory", last_name: "Doctor" }
                : d.owner;
            return d;
          });
          this.entryData = data.data;
          this.enableShowDetails();
          this.loadingStatus = false;
        } else if (url && custom) {
          if (this.entryName == "md_presc") {
            this.loadingStatus = true;
            this.entryData = await this.$prescription.getUserPrescriptions(
              this.mfid
            );
            // console.log("prescription data", this.entryData)
            // this.$emit('showDetails');
            this.loadingStatus = false;
          } else {
            this.loadingStatus = true;
            // this.$emit('showDetails', false);
            const { data } = await ApiService.get("custom", url);
            this.entryData = data.data;
            // this.$emit('showDetails');
            this.loadingStatus = false;
          }
        }
      } catch (error) {
        this.error = error;
        // console.log(error);
      }
    },
    enableShowDetails() {
      this.$emit("showDetailsCom");
    },
    listItemClicked(value) {
      this.$emit("listEntryClicked", value);
    },
    setFileIcon(type) {
      var path = "/media/svg/files/";
      if (type.includes("pdf")) {
        return path + "pdf.svg";
      } else if (type.includes("jpeg") || type.includes("jpg")) {
        return path + "jpg.svg";
      } else if (type.includes("mp4")) {
        return path + "mp4.svg";
      } else {
        return path + "doc.svg";
      }
    },
    selectFile(f) {
      this.selectedFile = f;
      this.isViewOpen = true;
    },
    closeFileView(d) {
      this.isViewOpen = d;
    }
  },
  mounted() {
    this.getEntryData();
  },
  computed: {
    filteredMDHistory() {
      return this.entryData.filter(
        entry =>
          ((entry.history_body &&
            entry.history_body
              .toLowerCase()
              .match(this.search.toLowerCase())) ||
            entry.date.match(this.search)) &&
          (this.type_selected == "" ||
            entry.history_type.id == this.type_selected)
      );
    },

    filteredVitals() {
      return this.entryData.filter(
        entry =>
          ((entry.health &&
            entry.health.toLowerCase().match(this.search.toLowerCase())) ||
            entry.reading.match(this.search) ||
            entry.method.match(this.search) ||
            entry.created_on.match(this.search)) &&
          (this.type_selected == "" ||
            entry.vital_type.id == this.type_selected)
      );
    },
    filteredDiagnoses() {
      return this.entryData.filter(
        entry =>
          (entry.examination &&
            entry.examination.toLowerCase().match(this.search.toLowerCase())) ||
          (entry.illness &&
            entry.illness.name
              .toLowerCase()
              .match(this.search.toLowerCase())) ||
          entry.created_on.match(this.search) ||
          entry.owner.first_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          entry.owner.last_name.toLowerCase().match(this.search.toLowerCase())
      );
    },
    filteredNotes() {
      return this.entryData.filter(
        entry =>
          entry.note_body.toLowerCase().match(this.search.toLowerCase()) ||
          entry.owner.first_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          entry.owner.last_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          entry.created_on.match(this.search)
      );
    },
    filteredPresc() {
      return this.entryData.filter(
        entry =>
          (entry.notes &&
            entry.notes.toLowerCase().match(this.search.toLowerCase())) ||
          entry.owner.first_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          entry.owner.last_name
            .toLowerCase()
            .match(this.search.toLowerCase()) ||
          entry.created_on.match(this.search)
      );
    },
    filteredFiles() {
      return this.entryData.filter(
        entry =>
          (entry.directus_files_id.filename_download &&
            entry.directus_files_id.filename_download
              .toLowerCase()
              .match(this.search.toLowerCase())) ||
          (entry.directus_files_id.title &&
            entry.directus_files_id.title
              .toLowerCase()
              .match(this.search.toLowerCase())) ||
          (entry.directus_files_id.type &&
            entry.directus_files_id.type
              .toLowerCase()
              .match(this.search.toLowerCase())) ||
          entry.directus_files_id.uploaded_on.match(this.search)
      );
    }
  }
};
</script>

<style></style>
